.links-overlaid {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
}

.links-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
}

.links-content .sinkable {
    display: inline-block;
}