.menu-left {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* .menu-right h2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
} */


.menu-bar .quadrii-container {
  margin-right: 25px;
  margin-bottom: 8px;
}

.menu-item.active {
  text-decoration: overline;
  text-decoration-thickness: 1;
}

.menu-right h2 span {
  display: inline-block;
}