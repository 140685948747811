.about-overlaid {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
}

.about-content h2 {
    font-weight: 300;
}

.about-content .highlight {
    display: inline-block;
    position: relative;
}

.about-content .highlight:after {
    content: '';
    position: absolute;
    z-index: -1;
    left: -0.1em;
    right: -0.1em;
    bottom: 0;
    top: 0;
    transition: top 200ms cubic-bezier(0, 0.8, 0.13, 1);
    transform: perspective(1000px) matrix3d(1, 0, 0, 0, 0, 0.98, -0.2, 0, 0, 0.2, 0.98, 0, 0, 0, 0, 1);
    background: #E0BC38;
}