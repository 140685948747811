@import 'assets/fonts.css';

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'GaramondCd', 'Baskerville', 'Baskerville Old Face', serif;
}

.small-gill {
  font-family: 'GillSansMN', 'Gill Sans',  'Gill Sans MT', sans-serif;
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: 3px;
  font-size: 15px;
}

* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0.3);
}

*::selection {
  background-color: black;
  color: white;
}

.clickable {
  cursor: pointer;
  transition: all .2s;
}

.sinkable:hover {
  transform: translateY(2px);
}

.os-theme-dark.os-scrollbar>.os-scrollbar-track>.os-scrollbar-handle,
.os-theme-dark.os-scrollbar:hover>.os-scrollbar-track>.os-scrollbar-handle {
  background-color: black !important;
  border-radius: 0 !important;
  cursor: pointer
}

h2 {
  font-weight: normal;
}

a {
  color: inherit;
}