body {
  padding: 0;
  margin: 0;
  background: white;
}

body.modal-open {
  height: 100vh;
  overflow-y: hidden;
}

.menu-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

h2 {
  margin: 10px 0 20px;
  font-size: 75px;
  user-select: none;
  font-weight: normal;
  line-height: 0.8;
}

.outskirt {
  padding: 50px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}


@media screen and (max-width: 1360px) {
  h2 {
    font-size: 44px;
  }

  h2.folio-title {
    font-size: 70px;
  }

  h5.folio-subtitle {
    font-size: 20px;
  }

  .quadrii-container {
    transform: scale(0.55);
    width: 20px !important;
  }

  .outskirt {
    padding: 30px;
  }

  .small-gill {
    font-size: 12px;
  }
}

@media screen and (max-width: 500px) {
  .site-title {
    display: none;
  }
}
