@font-face {
    font-family: 'GaramondCd';
    src: url('./fonts/Garamond/Gm-BkCondIta.otf') format('opentype');
    font-weight: 400;
    font-style: italic;
    font-stretch: condensed;
  }
  
  @font-face {
    font-family: 'GaramondCd';
    src: url('./fonts/Garamond/Gm-BkCond.otf') format('opentype');
    font-weight: 400;
    font-style: normal;
    font-stretch: condensed;
  }
  
  @font-face {
    font-family: 'GaramondCd';
    src: url('./fonts/Garamond/Gm-LtCond.otf') format('opentype');
    font-weight: 300;
    font-style: normal;
    font-stretch: condensed;
  }
  
  @font-face {
    font-family: 'GaramondCd';
    src: url('./fonts/Garamond/Gm-LtCondIta.otf') format('opentype');
    font-weight: 300;
    font-style: italic;
    font-stretch: condensed;
  }
  
  @font-face {
    font-family: 'GaramondCd';
    src: url('./fonts/Garamond/Gm-BdCond.otf') format('opentype');
    font-weight: 900;
    font-style: normal;
    font-stretch: condensed;
  }
  
  @font-face {
    font-family: 'GaramondCd';
    src: url('./fonts/Garamond/Gm-BdCondIta.otf') format('opentype');
    font-weight: 900;
    font-style: italic;
    font-stretch: condensed;
  }

  @font-face {
    font-family: 'GillSansMN';
    src: url('./fonts/Gill/GSN-R.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'GillSansMN';
    src: url('./fonts/Gill/GSN-B.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
  }

  @font-face {
    font-family: 'GillSansMN';
    src: url('./fonts/Gill/GSN-RI.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
  }

  @font-face {
    font-family: 'GillSansMN';
    src: url('./fonts/Gill/GSN-BI.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
  }