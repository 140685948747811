.single-slide {
    width: 100%;
    padding: 0 20px 25px;
}

*,*:focus,*:hover{
    outline:none;
}

.slick-slider, .slick-list, .slick-track, .slick-slide, .slick-slide>div, .single-slide {
    height: 100%;
    user-select: none;
}

.slick-dots {
    bottom: -11px;
}

.slick-dots li {
    margin: 0 2px;
    width: 17px;
}

.rolling-img {
    display: block;
    object-fit: contain;
    height: 100%;
    width: 100%;
}

.slick-dots li button:before {
    font-family: "Clash Display";
    font-size: 20px;
}