.quadrii-container {
    display: inline-block;
    overflow: visible;
    position: relative;
}

.quadrii {
    overflow: hidden;
    position: absolute;
}
