.folio-wall-container {
    min-height: 80vw;
    position: relative;
}

.folio-block {
    flex: none;
    position: absolute;
    display: inline-block;  
    will-change: transform;
    /* transition: all .2s; */
    transition: transform 10s cubic-bezier(.02,.89,.22,.99), filter .3s;
    /* transition: none !important; */
}

.folio-block.dim {
    filter: blur(20px) opacity(0.2);
    /* filter: brightness(0.2);
    z-index: 1; */
}

.folio-block h4 {
    opacity: 0;
    margin: 5px 0;
    position: absolute;
    pointer-events: none;
    transition: all .1s;
    color: white;
}

.folio-block:hover h4 {
    opacity: 1;
    letter-spacing: 5px;
    transition: letter-spacing 5s cubic-bezier(.02,.89,.22,.99), opacity 1.5s;
}

.folio-block-mobile img {
    width: 100%;
}

.folio-block-mobile h4 {
    color: white;
    margin-top: 5px;
}


.folio-block-mobile {
    margin-top: 50px;
}

@media screen and (max-width: 600px) {
    .site-title {
      display: none;
    }
  
    .folio-wall-container {
      display: none;
    }
  
    .folio-wall-container-mobile {
      display: block;
    }
  }
  
  @media screen and (min-width: 601px) {
    .folio-wall-container {
      display: block;
    }
  
    .folio-wall-container-mobile {
      display: none;
    }
  }