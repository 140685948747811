  .texture-container svg {
    width: calc(100vmin - 4rem);
    grid-row: 1;
    grid-column: 1;
    overflow: hidden;
    width: 100%;
    height: 100%;
  }
  
  .texture {
    grid-row: 1;
    grid-column: 1;
    height: calc(100vmin - 4rem);
    aspect-ratio: 600 / 900;
    position: relative;
    z-index: 1;
  }
  